<template lang="pug">
vs-sidebar.items-no-padding(position-right='' v-model='active' parent='body' default-index='55555551')
  .h-100(v-if='isLoadingDevice || isUpdatingDevice || isDeletingDevice || isLoadingCustomer || isLoadingPool')
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement des d&eacute;tails de l'appareil...
  .h-full(v-else-if='device.id')
    .sidebar-header.py-1.px-2
      .d-flex.align-items-center
        div
          h3 {{ device.label }}
          small {{ device.activity }}
      feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='active = !active')
    vs-divider.m-0
    .sidebar-action.py-05.px-2
        .d-flex.align-items-center
          b-button.btn-icon(v-if='archived' variant='warning' @click='restoreDeviceLocal(device.id, device.label)')
            feather-icon(icon='ArrowDownIcon')
            | Restaurer
          b-button.btn-icon(v-else variant='primary' @click='editDevice(device.id)' :disabled='archived')
            feather-icon(icon='EditIcon')
            | Modifier
        b-button-group
          b-button.btn-icon(variant='outline-danger' @click="deleteDeviceLocal(device.id, device.label)" v-if="archived")
            feather-icon.mr-50(icon='TrashIcon')
            | Supprimer d&eacute;finitivement
          b-button(v-else variant="outline-danger" class="btn-icon" @click="archiveDeviceLocal(device.id, device.label)")
            feather-icon(icon='ArchiveIcon')
    vs-divider.m-0
    .sidebar-content
      div(v-show='device.brand || device.date || device.deviceType || device.label')
        h4.mb-1.text-primary
          feather-icon.mr-1(icon='InfoIcon')
          | Informations
        ul.listTab
          li(v-show='device.label')
            span Nom de l'appareil
            span {{ device.label }}
          li(v-show='device.brand')
            span Marque
            span {{ device.brand.label }}
          li(v-show='device.date')
            span Installation
            span {{ this.dateConvert(device.date) }}
          li(v-show='device.deviceType')
            span Type d'appareil 
            span {{ device.deviceType.label }}
          li(v-show='device.commentary')
            span Commentaire
            span {{ device.commentary }}
        vs-divider
      div(v-show='pool.label || pool.address || pool.city || pool.postalCode')
        h4.mb-1.text-primary
          feather-icon.mr-1(icon='MapPinIcon')
          | Bassin
        ul.listTab
          li(v-show='pool.label')
            span Nom 
            span {{ pool.label }}
          li(v-show='pool.address')
            span Address
            span {{ pool.address }}
          li(v-show='pool.city')
            span Ville
            span {{ pool.city }}
          li(v-show='pool.postalCode')
            span Code postal
            span {{ pool.postalCode }}
        vs-divider
      div(v-show='customer.firstName || customer.email || customer.tel')
        h4.mb-1.text-primary
          feather-icon.mr-1(icon='UserIcon')
          | Client
        ul.listTab
          li(v-show='customer.firstName')
            span Nom 
            span {{ `${customer.firstName} ${customer.lastName}` }}
          li(v-show='customer.email')
            span E-mail
            span {{ customer.email }}
          li(v-show='customer.email')
            span Tel
            span {{ customer.tel1 }}
        vs-divider
</template>

<script>
import { mapGetters, mapActions } from "vuex";


export default {
  data() {
    return {
      archived: false,
    };
  },
  computed: {
    ...mapGetters([
      "customer",
      "pool",
      "device",
      "isUpdatingDevice",
      "compagniesList",
      "isLoadingDevice",
      "isLoadingCustomer",
      "isLoadingPool",
      "isDeletingDevice",
      "isOpenSidebarDevice",
    ]),
    active: {
      get() {
        return this.isOpenSidebarDevice;
      },
      set(val) {
        this.$store.commit("SET_IS_OPEN_SIDEBAR_DEVICE", val);
      },
    },
  },
  methods: {
    ...mapActions([
      "updateDevice",
      "deleteDevice",
      "isArchivingDevice",
      "archiveDevice",
      "restoreDevice",
      "fetchDevice",
      "fetchDeviceArchived",
      "fetchCustomer",
      "fetchPool",
    ]),
    getDeviceArchivedDetails(id, archived) {
      this.archived = archived;
      if (id > 0) {
        this.fetchDeviceArchived(id).then((res) => {
          this.device = res;
        });
      } else {
        this.device = {};
      }
    },
    getDeviceDetails(id) {
      this.archived = false;
      this.fetchDevice(id).then((res) => {
        this.findCustomerAndPool(res.poolId)
      });
    },
    async editDevice(id) {
      this.$store.commit("SET_IS_OPEN_SIDEBAR_DEVICE", false);
      this.$router.push({
        name: "edit-device",
        params: {
          id: id,
          title: "Edit : " + this.device.label,
          tips:
            "Editer le bassin : " +
            this.device.label
        },
      });
    },
    archiveDeviceLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveDevice(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_DEVICE", false);
          }
        });
    },
    restoreDeviceLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title: 'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreDevice(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_DEVICE", false);
          }
        });
    },
    async findCustomerAndPool(id){
      let fetchedPool = await this.fetchPool(id)
      this.fetchCustomer(fetchedPool.customerId)
    },
    dateConvert(datePull) {
      const date = new Date(datePull);
      const jour = date.getDate();
      const mois = date.getMonth() + 1;
      const annee = date.getFullYear();
      const heures = date.getHours();
      const minutes = date.getMinutes();
      const dateFormatee = `${jour < 10 ? '0' + jour : jour}/${mois < 10 ? '0' + mois : mois}/${annee} ${heures < 10 ? '0' + heures : heures}:${minutes < 10 ? '0' + minutes : minutes}`;
      return dateFormatee;
    },
    deleteDeviceLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteDevice(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_DEVICE", false);
          }
        });
    },
  },
};
</script>
